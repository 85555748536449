import React, { Component } from 'react'

import SEO from '../../components/seo'

import SimpleMd from '../../components/simpleMd'

class ProductsPage extends Component {

  render() {
    return (
      <>
        <SEO title="Products" />

        <section className="products-content">
          <SimpleMd filename="why-rigpro" />
        </section>

      </>
    )
  }
}

export default ProductsPage